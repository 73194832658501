import React from 'react'
import Link from 'gatsby'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

const Nav = (props) => (
  <nav id="nav" className={props.sticky ? 'alt' : ''}>
    <Scrollspy items={['intro', 'first', 'second', 'cta']} currentClassName="is-active" offset={-300}>
      <li>
        <Scroll type="id" element="intro">
          <a href="#">Contact</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="first">
          <a href="#">Prijslijst</a>
        </Scroll>
      </li>
    </Scrollspy>
  </nav>
)

export default Nav
