import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import logo from '../assets/images/logo.png'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="BySoes | Cakes and more" />

        <Header />

        <Waypoint
          onEnter={this.handleWaypointEnter}
          onLeave={this.handleWaypointLeave}
        >
        </Waypoint>

        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Welkom bij BySoes</h2>
                </header>

                <p>
                  Ben je opzoek naar een taart voor een speciale gelegenheid dan kan BySoes een custum cake voor u
                  maken.
                  BySoes maakt heerlijke taarten, cupcakes en meerdere sweets.

                  Voor meer informatie of bestellingen kunt u mailen naar
                  <strong><a href="mailto:bysoes@outlook.com">bysoes@outlook.com</a></strong> of bellen
                  naar <strong>0624225959</strong>. Wij nemen zo snel mogelijk contact met u op.
                </p>
              </div>
              <span className="image">
                <img src={logo} alt="logo" width="height" />
              </span>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>Prijslijst</h2>
            </header>

            <span className="icon major style3 fa-birthday-cake">
            </span>

            <h3 className="align-left">Drippcake</h3>
            <ul>
              <li>Drippcake klein (10 personen) <span className="float-right">v.a. €50,00</span></li>
              <li>Drippcake middel (15 personen) <span className="float-right">v.a. €70,00</span></li>
              <li>Drippcake groot (20 personen)<span className="float-right">v.a. €90,00</span></li>
            </ul>

            <h3 className="align-left">Vanille en chocolade cake €4,50 per persoon Red velvet €2,50 meerprijs</h3>
            <ul>
              <li>Basic klein (10 personen) <span className="float-right">v.a €45,00</span></li>
              <li>Basic middel (15 personen) <span className="float-right">v.a. €67,50</span></li>
              <li>Basic groot (20 personen) <span className="float-right">v.a. €90,00</span></li>
            </ul>

            <h3 className="align-left">Cupcakes (gaan per 12 st.)</h3>
            <ul>
              <li>Cupcakes met toef <span className="float-right">€2,75</span></li>
              <li>Cupcakes met fondant <span className="float-right">€3,25</span></li>
              <li>Vegan cupcakes <span className="float-right">€3,25</span></li>
            </ul>

            <h3 className="align-left">Donuts (gaan per 10 st.)</h3>
            <ul>
              <li>Donuts zonder decoratie <span className="float-right">€2,00</span></li>
              <li>Donuts met decoratie <span className="float-right">€2,50</span></li>
            </ul>

            <h3 className="align-left">Oreonbonbons (gaan per 10 st.)</h3>
            <ul>
              <li>Oreonbonbons zonder decoratie <span className="float-right">€2,00</span></li>
              <li>Oreonbonbons met decoratie <span className="float-right">€2,50</span></li>
            </ul>

            <h3 className="align-left">Cakepops (gaan per 10 st.)</h3>
            <ul>
              <li>Cakepop zonder decoratie <span className="float-right">€1,75</span></li>
              <li>Cakepop met decoratie <span className="float-right">€2,00</span></li>
              <li>Magnum pops zonder decoratie <span className="float-right">€3,00</span></li>
              <li>Magnum pops met decoratie <span className="float-right">€3,50</span></li>
            </ul>

            <h3 className="align-left">Aardbeien met chocolade (gaan per 10 st.)</h3>
            <ul>
              <li>Aardbeien zonder decoratie <span className="float-right">€1,00</span></li>
              <li>Aardbeien met decoratie <span className="float-right">€1,50</span></li>
            </ul>

            <h3 className="align-left">Pannenkoeken (gaan per 10 st.)</h3>
            <ul>
              <li>Pannekoeken <span className="float-right">€1,25</span></li>
              <li>Pannekoeken op een stokje <span className="float-right">€1,75</span></li>
            </ul>

            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/overige" className="button">
                    Meer
                  </Link>
                </li>
              </ul>
            </footer>
          </section>

        </div>
      </Layout>
    )
  }
}

export default Index
